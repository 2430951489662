@tailwind base;
@tailwind components;
@tailwind utilities;

.animated-border {
  width: 100%;
  height: 66px;
  line-height: 45px;
  padding: 10px;
  border-bottom: 2px solid transparent;
  position: relative;
}

.animated-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ff7800;
  transition: width 0.3s ease-out;
}

.animated-border:hover::after {
  width: 100%;
}
